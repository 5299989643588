import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  axiosDeleteData,
  axiosGetData,
  axiosPatchData,
  axiosPostData,
  axiosPostQueue,
} from '../../helpers/axios';
import { camelToSnake } from '../../dashboard/utils';

const formatCannabisBatchData = (data) => {
  const payload = camelToSnake(data);

  ['thc_delta8', 'thc_delta9'].forEach((key) => {
    if (payload[key]) {
      payload[key.replace('delta', 'delta_')] = payload[key];
      delete payload[key];
    }
  });

  return payload;
};

// we do want to use isNaN here to see if the id will coerce to an int,
// or if it is just a label for uniqueness (ie, a string)
export const isPersisted = (id) =>
  // eslint-disable-next-line no-restricted-globals
  !!id && !isNaN(id);

export const addBatchVariant = createAction('addBatchVariant');

export const addTerpene = createAction('addTerpene');

export const addSampleCase = createAction('addSampleCase');

export const createCannabisBatch = createAsyncThunk(
  'createCannabisBatch',
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPostData({
      data: formatCannabisBatchData(data),
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const createCannabisBatchCOA = createAsyncThunk(
  'createCannabisBatchCOA',
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPostQueue({
      data,
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const deleteCannabisBatchCOA = createAsyncThunk(
  'deleteCannabisBatchCOA',
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosDeleteData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const getCannabisBatch = createAsyncThunk(
  'getCannabisBatch',
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const reanalyzeCannabisBatchCOA = createAsyncThunk(
  'reanalyzeCannabisBatchCOA',
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPostData({
      data,
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const removeBatchVariant = createAction('removeBatchVariant');

export const removeTerpene = createAction('removeTerpene');

export const saveCannabisBatch = createAsyncThunk(
  'saveCannabisBatch',
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPatchData({
      data: formatCannabisBatchData(data),
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const setPayload = createAction('setPayload');

export const updateBatchVariant = createAction('updateBatchVariant');

export const updateTerpene = createAction('updateTerpene');
